import { Component, OnInit, OnDestroy } from '@angular/core';
import { Util } from 'src/app/util';
import { Remdoc } from 'src/app/models/remdoc';
import { ReceivedTransferService } from 'src/app/services/received-transfer.service';
import { Subscription } from 'rxjs';
import { ReceivedTransfer } from 'src/app/models/receivedTransfer';
import { Router, NavigationEnd } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-rt-list',
  templateUrl: './rt-list.component.html',
  styleUrls: ['./rt-list.component.css']
})
export class RtListComponent implements OnInit, OnDestroy {
  public rtList: Array<ReceivedTransfer> = [];
  private rtListSubscription: Subscription;
  private navigationSubscription: Subscription;
  public client: ClientView;
  constructor(
    private receivedTransferService: ReceivedTransferService,
     private router: Router,
     private clientSharedService: ClientSharedService
     )  {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.getRtList();
  }

  setStatusColor(key: string) {
    return Util.setStatus('credoc_list', key).color;
  }

  setStatusText(key: string) {
    return Util.setStatus('credoc_list', key).text;
  }

  printRT(rtId) {
    this.receivedTransferService.printReport(rtId, this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }

  getRtList(): void {
    this.rtListSubscription = this.receivedTransferService
      .getRtList(this.client.id)
      .subscribe(resp => {
        this.rtList = resp.sort((rdi1, rdi2) => {
          return rdi2.requestId.localeCompare(rdi1.requestId);
        });
      });
  }
  openRtRecap(rt: Remdoc) {}

  ngOnDestroy(): void {
    if (this.rtListSubscription) {
      this.rtListSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
