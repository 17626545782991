import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RemdocService } from 'src/app/services/remdoc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Remdoc } from 'src/app/models/remdoc';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { HomeService } from 'src/app/services/home.service';
import { Status, Util } from 'src/app/util';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-recap-initiator',
  templateUrl: './recap-initiator.component.html',
  styleUrls: ['./recap-initiator.component.css']
})
export class RemdocRecapInitiatorComponent implements OnInit, OnDestroy {
  message = `Quelle est votre décision concernant l'ouverture de la Remise Documentaire import`;
  actionButtonClicked = '';
  taskChanged = false;
  ACCEPT = 'accept';
  REJECT = 'reject';
  EMPTY = '';
  id: number;
  remdoc: Remdoc;
  decision: string;
  public client: ClientView;
  private dataServiceSubscription: Subscription;
  remdocForm: FormGroup;
  @ViewChild('treatRemdocAlert') treatRemdocAlert: AlertComponent;
  snackbarMessage = 'La Remdoc a bien été soumis pour signature.';
  decisionMessage =
    'Vous êtes sur le point de soumettre cette décision pour signature. Êtes-vous sûr de vouloir continuer ?';
  constructor(
    private remdocService: RemdocService,
    private route: ActivatedRoute,
    private router: Router,
    private homeService: HomeService,
    private dataService: DataService,
    private fb: FormBuilder,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.remdocForm = this.fb.group({
      instructions: [null, [Util.specialCharValidator()]]
    });
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.decision = data.decision;
      }
    );
    this.terminateTraitement = this.terminateTraitement.bind(this);
    this.id = this.route.snapshot.params['id'];
    this.getRemdoc();
  }
  get instructions(): FormControl {
    return this.remdocForm.get('instructions') as FormControl;
  }
  confirm() {
    this.mapFormToRemdoc();
    switch (this.actionButtonClicked) {
      case this.ACCEPT:
        this.acceptRemdoc(this.terminateTraitement);
        return;
      case this.REJECT:
        this.rejectRemdoc(this.terminateTraitement);
        return;
    }
  }

  terminateTraitement() {
    this.treatRemdocAlert.openAlert(
      this.snackbarMessage,
      this.remdoc.requestId
    );
    setTimeout(() => {
      this.treatRemdocAlert.closeAlert();
      this.router.navigate(['/tasks']);
    }, 1000);
  }

  getRemdoc() {
    this.remdocService.getRemdoc(this.id, this.client.id).subscribe(res => {
      this.remdoc = res;
      this.mapRemdocToForm();
    });
  }
  mapRemdocToForm() {
    this.instructions.setValue(this.remdoc.instructions);
  }
  mapFormToRemdoc() {
    this.remdoc.instructions = this.instructions.value;
  }

  acceptRemdoc(callback) {
    this.taskChanged = true;
    this.remdocService.acceptRemdoc(this.remdoc, this.client.id).subscribe(callback);
  }

  rejectRemdoc(callback) {
    this.taskChanged = true;
    this.remdocService.rejectRemdoc(this.remdoc, this.client.id).subscribe(callback);
  }
  unlockTask() {
    this.homeService.unlockTask(this.remdoc.camundaId).subscribe();
  }
  ngOnDestroy(): void {
    if (
      this.remdoc &&
      !this.taskChanged &&
      this.remdoc.status === Status.TO_TREAT
    ) {
      this.unlockTask();
    }
    this.dataService.changeData({
      product: null,
      summary: false,
      paymentId: null,
      lcId: null,
      taskDefinitionKey: null,
      taskId: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
  }
}
