import { Component, OnInit, OnDestroy } from "@angular/core";
import { HomeService } from "src/app/services/home.service";
import { Task } from "src/app/models/task";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";
import {
  Status,
  Operations,
  Util,
  ProrogationViews,
  ExpirationViews
} from "src/app/util";
import { ProrogService } from "src/app/services/prorog.service";
import { ExpirService } from "src/app/services/expir.service";
import { LastComment } from "src/app/models/lastComment";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { ClientSharedService } from "src/app/services/client-shared-service";
import { ClientView } from "src/app/models/client-view";

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.css"]
})
export class TasksComponent implements OnInit, OnDestroy {
  public lcs: Task[];
  public client: ClientView;
  constructor(
    protected homeService: HomeService,
    protected dataService: DataService,
    protected router: Router,
    protected proroService: ProrogService,
    protected expirService: ExpirService,
    protected clientSharedservice: ClientSharedService
  ) {}

  ngOnInit() {
      this.client = this.clientSharedservice.getClient();
      this.getTasks();
  }

  getTasks(): void {
    this.homeService
    .getTasks(this.client.id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(lcs => {
      this.lcs = lcs;
      this.lcs = this.lcs.sort((a: Task, b: Task) => {
        return (
          new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
        );
      });
    });
  }
  ngOnDestroy(): void {
  }
  isToValidateRealization(task: Task): boolean {
    return (
      task.operation === Operations.REALIZATION &&
      (task.taskDefinitionKey === Status.TO_VALIDATE ||
        task.taskDefinitionKey === Status.TO_REGULARIZE)
    );
  }

  customRoute(task: Task): void { 
    if (Util.operations.indexOf(task.operation) < 0) {
      return;
    }
    switch (task.operation) {
      case Operations.REFINANCING_EXTENSION:
      case Operations.CREDOC_IMPORT_EXTENSION:
      case Operations.REMDOC_IMPORT_EXTENSION:
      case Operations.TRANSFER_ISSUED_EXTENSION:
        this.routeToProrogation(task);
        return;
      case Operations.REFINANCING_EXPIRATION:
        this.routeToExpiration(task);
        return;
      case Operations.REMDOC_OPENING:
        this.routeToRemdocOpening(task);
        return;
      case Operations.TRANSFER_ISSUED:
        this.routeToVRE(task);
        return;
      case Operations.REMDOC_PAYMENT:
        this.routeToRemdocPayment(task);
        return;
      case Operations.TRANSFER_RECEIVED:
        this.routeToReceivedTransfer(task);
        return;
      default:
        this.customRoute_(task);
        return;
    }
  }

  setLastComment(task: Task): LastComment {
    const lastComment: LastComment =
      task && task.comments && task.comments.length > 0
        ? task.comments[0]
        : { fullMessage: null, time: null, userId: null };
    return lastComment;
  }

  routeToProrogation(task: Task): void {
    // Set the last comment object
    const view =
      task.taskDefinitionKey === Status.TO_SIGN
        ? "recap"
        : task.taskDefinitionKey === Status.TO_REGULARIZE ||
          task.taskDefinitionKey === Status.TO_REGULARIZE_MO
        ? "form"
        : "";
    const prorogationView =
      task.taskDefinitionKey === Status.TO_SIGN
        ? ProrogationViews.PROROGATION_VALIDATION
        : task.taskDefinitionKey === Status.TO_REGULARIZE ||
          task.taskDefinitionKey === Status.TO_REGULARIZE_MO
        ? ProrogationViews.PROROGATION_UPDATE
        : "";
    if (view !== "") {
      this.proroService.changeProrogFormData({
        prorogationView
      });

      this.dataService.changeData({
        product: this.getProductName(task.requestId),
        summary: null,
        taskId: task.id,
        lcId: task.demandeId,
        paymentId: task.paymentId,
        taskDefinitionKey: null,
        lastComment: this.setLastComment(task),
        action: null,
        decision: null
      });
      const prorogRoute = "prorogation/" + view + "/" + task.extensionId;
      this.router.navigate([prorogRoute]);
    }
  }

  getProductName(requestId: string): string {
    return Util.getProduct(requestId).name;
  }

  getProductShortName(requestId: string): string {
    return requestId.includes("RDI")
      ? "RDI"
      : requestId.includes("LC")
      ? "CDI"
      : requestId.includes("VRI")
      ? "VRI"
      : requestId.includes("VRE")
      ? "VRE"
      : "";
  }

  routeToExpiration(task: Task): void {
    // Set the last comment object
    const view =
      task.taskDefinitionKey === Status.TO_SIGN
        ? "recap"
        : task.taskDefinitionKey === Status.TO_REGULARIZE ||
          task.taskDefinitionKey === Status.TO_REGULARIZE_MO ||
          task.taskDefinitionKey === Status.TO_TREAT
        ? "form"
        : "";
    const expirationView =
      task.taskDefinitionKey === Status.TO_SIGN
        ? ExpirationViews.EXPIRATION_VALIDATION
        : task.taskDefinitionKey === Status.TO_REGULARIZE ||
          task.taskDefinitionKey === Status.TO_REGULARIZE_MO ||
          task.taskDefinitionKey === Status.TO_TREAT
        ? ExpirationViews.EXPIRATION_INITIATION
        : "";

    if (view !== "") {
      this.expirService.changeExpirFormData({
        expirationView
      });

      this.dataService.changeData({
        product: this.getProductName(task.requestId),
        summary: null,
        lcId: task.demandeId,
        paymentId: task.paymentId,
        taskId: task.id,
        taskDefinitionKey: null,
        lastComment: this.setLastComment(task),
        action: null,
        decision: null
      });

      const prorogRoute = "expiration/" + view + "/" + task.expirationId;
      this.router.navigate([prorogRoute]);
    }
  }

  customRoute_(task: Task): void {
    // Set the last comment object
    this.dataService.changeData({
      product: null,
      summary:
        task.taskDefinitionKey === Status.TO_SIGN ||
        this.isToValidateRealization(task) ||
        task.operation === Operations.PAYMENT ||
        task.operation === Operations.CANCELLATION,
      lcId: task.demandeId,
      paymentId: task.paymentId,
      taskDefinitionKey: task.taskDefinitionKey,
      lastComment: this.setLastComment(task),
      action: task.operation,
      taskId: task.id,
      decision: Util.setDecisionText(task.decision)
    });
    if (task.taskDefinitionKey !== Status.WAITING_FOR_TREATMENT) {
      this.router.navigate(["/lc"]);
    }
  }

  routeToVRE(task: Task) {
    // Set the last comment object
    this.dataService.changeData({
      product: null,
      summary: null,
      action: null,
      decision: null,
      lastComment: this.setLastComment(task),
      lcId: null,
      paymentId: task.paymentId,
      taskDefinitionKey: null,
      taskId: null
    });
    switch (task.taskDefinitionKey) {
      case Status.TO_COMPLETE:
      case Status.TO_REGULARIZE:
      case Status.TO_REGULARIZE_MO:
        this.router.navigate(["/issued-transfer/init/edit/" + task.demandeId]);
        break;
      case Status.TO_SIGN:
        this.router.navigate(["/issued-transfer/validate/" + task.demandeId]);
        break;
      default:
        return;
    }
  }

  routeToRemdocOpening(task: Task) {
    switch (task.taskDefinitionKey) {
      case Status.TO_TREAT:
        this.lockRemdocTaskAndRoute(task);
        break;
      case Status.TO_REGULARIZE:
        this.dataService.changeData({
          product: "",
          summary: null,
          action: null,
          decision: Util.setDecisionText(task.decision),
          lastComment: null,
          lcId: null,
          paymentId: null,
          taskId: null,
          taskDefinitionKey: null
        });
        this.router.navigate(["/remdoc/initiator/" + task.demandeId]);
        break;
      case Status.TO_SIGN:
        this.dataService.changeData({
          product: null,
          summary: null,
          action: null,
          decision: Util.setDecisionText(task.decision),
          lastComment: null,
          lcId: null,
          paymentId: null,
          taskDefinitionKey: null,
          taskId: null
        });
        this.router.navigate(["/remdoc/validator/" + task.demandeId]);
        break;
    }
  }

  routeToRemdocPayment(task: Task) {
    // Set the last comment object
    this.dataService.changeData({
      product: null,
      summary: null,
      action: null,
      decision: null,
      lastComment: this.setLastComment(task),
      lcId: null,
      paymentId: task.paymentId,
      taskDefinitionKey: null,
      taskId: null
    });
    switch (task.taskDefinitionKey) {
      case Status.TO_TREAT:
      case Status.TO_REGULARIZE:
      case Status.TO_REGULARIZE_MO:
        this.router.navigate(["/remdoc/payment/initiator/" + task.demandeId]);
        break;
      case Status.TO_SIGN:
        this.router.navigate(["remdoc/payment/validator/" + task.demandeId]);
        break;
      default:
        return;
    }
  }

  routeToReceivedTransfer(task: Task) {
    switch (task.taskDefinitionKey) {
      case Status.TO_TREAT:
      case Status.TO_REGULARIZE:
      case Status.TO_REGULARIZE_MO:
        this.lockRtTaskAndRoute(task);
        break;
      case Status.TO_SIGN:
        this.router.navigate(["/receivedTransfer/validator/" + task.demandeId]);
        break;
      default:
        return;
    }
  }

  lockRemdocTaskAndRoute(task: Task): void {
    if (task.isLocked === null || task.isLocked === false) {
      this.homeService.lockTask(task.processInstanceId, this.client.id).subscribe(
        res => {},
        err => {}
      );
      this.router.navigate(["/remdoc/initiator/" + task.demandeId]);
    }
  }

  lockRtTaskAndRoute(task: Task): void {
    this.dataService.changeData({
      product: null,
      summary: null,
      action: null,
      decision: null,
      lastComment: this.setLastComment(task),
      lcId: null,
      paymentId: null,
      taskDefinitionKey: null,
      taskId: null
    });
    if (task.isLocked === null || task.isLocked === false) {
      this.homeService.lockTask(task.processInstanceId, this.client.id).subscribe(
        _res => {},
        _err => {}
      );
      this.router.navigate(["/receivedTransfer/initiator/" + task.demandeId]);
    }
  }

  getOperationLablel(operation: string) {
    return Util.setOperationText(operation);
  }

  setStatusText(key: string) {
    return Util.setStatus("tasks", key).text;
  }

  setStatusClass(key: string) {
    return Util.setStatus("tasks", key).className;
  }

  getTypeProcess(key: string) {
    return this.getProductName(key);
  }

  setLock(task: Task): string {
    return task.isLocked ? "lock.png" : "unlock.png";
  }

  lockPresent(task: Task): boolean {
    return typeof task.isLocked === "boolean";
  }
}
