import { Component, OnInit, Input } from '@angular/core';
import { Remdoc } from 'src/app/models/remdoc';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-rdi-payment-recap',
  templateUrl: './rdi-payment-recap.component.html',
  styleUrls: ['./rdi-payment-recap.component.css']
})
export class RdiPaymentRecapComponent implements OnInit {
  @Input() remdoc: Remdoc;
  @Input() payment: PaymentOperation;
  AT_SIGHT = 'AT_SIGHT';
  public rdiTypes = {
    AT_SIGHT: 'A vue',
    BY_ACCEPTANCE: 'Avec acceptation',
    BY_ACCEPTANCE_DOWNSTREAM: 'Avec Acceptation et Aval'
  };

  constructor() {}

  ngOnInit() {}

  setRegulationStatus(): string {
    return Util.setStatus('recap', this.payment.status).text;
  }

  negociationTrackBy(index: number, obj: any): any {
    return index;
  }

  getNegociationTypeText(type): string {
    return Util.getNegociationTypeText(type);
  }

  isRefinancingText(): string {
    return this.payment.refinancing ? 'Oui' : 'Non';
  }
}
