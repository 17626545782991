import { PaymentOperation } from './paymentOperation';

export class Remdoc {
  public id: number;
  public requestId: string;
  public addDate: Date;
  public receiptDate: Date;
  public beneficiary: string;
  public beneficiaryAccount: string;
  public type: string;
  public amount: number;
  public currency: string;
  public status: string;
  public camundaId: string;
  public clientId: number;
  public clientName: string;
  public referenceDelta: string;
  public referenceWf: string;
  public instructions: string;
  public payment: PaymentOperation;
  public initiator: String;
  constructor() {}
}
