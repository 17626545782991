import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemdocService } from 'src/app/services/remdoc.service';
import { Remdoc } from 'src/app/models/remdoc';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { CreateLcService } from 'src/app/services/create-lc.service';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl
} from '@angular/forms';
import { PaymentValidators } from 'src/app/components/lc/payment-operation/validators/validators';
import { NegociationTypes, Util } from 'src/app/util';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { Negociation } from 'src/app/models/negociation';
import { LastComment } from 'src/app/models/lastComment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-payment-rdi-initiator',
  templateUrl: './payment-rdi-initiator.component.html',
  styleUrls: ['./payment-rdi-initiator.component.css']
})
export class PaymentRdiInitiatorComponent implements OnInit, OnDestroy {
  public id: number;
  public remdoc: Remdoc;
  public payment: PaymentOperation;
  private dataServiceSubscription: Subscription;
  private negotiatedAmountSubscription: Subscription;
  private getRemdocSubscription: Subscription;
  private negociationTypeSubscription: Subscription;
  private getPaymentSubscription: Subscription;
  private refinancingChangeSubscription: Subscription;
  private getAccountsSubscription: Subscription;
  public client: ClientView;
  lastComment: LastComment;
  paymentForm: FormGroup;
  AT_SIGHT = 'AT_SIGHT';
  private submitRdiPaymentMessage =
    'Le réglement a bien été soumis pour signature.';
  private saveRdiPaymentMessage = 'Le réglement a bien été enregistré.';
  @ViewChild('remdoc_payment_alert') remdocPaymentAlert: AlertComponent;
  refinancingForm: FormGroup;
  datePickerConfig = Util.date_input_config;
  public accounts: string[] = [];
  confirmationShown = false;
  public rdiTypes = {
    AT_SIGHT: 'A vue',
    BY_ACCEPTANCE: 'Avec acceptation',
    BY_ACCEPTANCE_DOWNSTREAM: 'Avec Acceptation et Aval'
  };
  submitPaymentMessage =
    'Vous êtes sur le point de soumettre les nouvelles instructions de paiement pour signature.';
  constructor(
    private route: ActivatedRoute,
    private remdocService: RemdocService,
    private dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private createLcService: CreateLcService,
    private localeService: BsLocaleService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.localeService.use('fr');
    this.client = this.clientSharedService.getClient();
    const remdocId = this.route.snapshot.params['remdocId'];
    this.getAccounts();
    this.paymentForm = this.fb.group({
      importationTitles: this.fb.array([]),
      accountNumber: this.fb.control('', [Validators.required]),
      negociations: this.fb.array([], [Validators.required]),
      refinancing: [false, [Validators.required]],
      instructions: this.fb.control('')
    });

    this.getRemdoc(remdocId);

    this.refinancingForm = this.fb.group({
      amountToBeRefinanced: [null],
      duration: [null]
    });

    this.paymentForm.addControl('refinancingForm', this.refinancingForm);
    this.refinancingChangeSubscription = this.paymentForm
      .get('refinancing')
      .valueChanges.subscribe(newVal => this.onRefinancingValueChange(newVal));

    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.getRdiPayment(+data.paymentId);
        this.lastComment = data.lastComment;
      }
    );
  }

  onRefinancingValueChange(newVal: boolean): void {
    if (newVal) {
      this.refinancingForm
        .get('amountToBeRefinanced')
        .setValidators([
          Validators.required,
          PaymentValidators.amountRefinancingValidator(
            this.payment.amountPayment
          ),
          Validators.min(0)
        ]);
      this.refinancingForm
        .get('duration')
        .setValidators([Validators.required, Validators.min(0)]);
    } else {
      this.refinancingForm.get('amountToBeRefinanced').clearValidators();
      this.refinancingForm.get('duration').clearValidators();
      this.refinancingForm.get('amountToBeRefinanced').setValue(null);
      this.refinancingForm.get('duration').setValue(null);
      this.refinancingForm.get('amountToBeRefinanced').updateValueAndValidity();
      this.refinancingForm.get('duration').updateValueAndValidity();
    }
  }

  getAccounts() {
    this.getAccountsSubscription = this.createLcService.getRemdocImportAccounts(this.client.id).subscribe(
      accounts => {
        this.accounts = accounts;
      },
      err => {
        this.accounts = [];
      }
    );
  }

  getRdiPayment(rdiPaymentId: number) {
    this.getPaymentSubscription = this.createLcService
      .getPayment(rdiPaymentId, this.client.id)
      .subscribe(payment => {
        this.payment = payment;
        if (!this.payment['importationTitles']) {
          this.payment['importationTitles'] = [];
        }
        this.mapPaymentToForm(this.payment);
      });
  }

  addTitle(titleValue) {
    const ctrl = <FormArray>this.importationTitles;
    const ctrlItem = this.fb.control(titleValue, [
      Validators.required,
      Validators.maxLength(19),
      Validators.minLength(19)
    ]);
    ctrlItem.setValue(titleValue);
    ctrl.push(ctrlItem);
    ctrl.updateValueAndValidity();
  }

  importationTitlesTrackBy(index: number, obj: any): any {
    return index;
  }

  getRemdoc(remdocId: number) {
    this.getRemdocSubscription = this.remdocService
      .getRemdoc(remdocId, this.client.id)
      .subscribe(res => {
        this.remdoc = res;
        if (this.remdoc.type === this.AT_SIGHT) {
          this.importationTitles.enable();
          this.importationTitles.setValidators([
            Validators.required,
            Validators.minLength(1)
          ]);
        } else {
          this.importationTitles.disable();
        }
      });
  }

  ngOnDestroy(): void {
    this.dataService.changeData({
      product: null,
      summary: false,
      paymentId: null,
      lcId: null,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      taskId: null,
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unsubscribe();
    }
    if (this.refinancingChangeSubscription) {
      this.refinancingChangeSubscription.unsubscribe();
    }
    if (this.getPaymentSubscription) {
      this.getPaymentSubscription.unsubscribe();
    }
    if (this.getRemdocSubscription) {
      this.getRemdocSubscription.unsubscribe();
    }
    if (this.negotiatedAmountSubscription) {
      this.negotiatedAmountSubscription.unsubscribe();
    }
    if (this.negociationTypeSubscription) {
      this.negociationTypeSubscription.unsubscribe();
    }
  }

  get accountNumber(): FormControl {
    return this.paymentForm.get('accountNumber') as FormControl;
  }

  get negociations(): FormArray {
    return this.paymentForm.get('negociations') as FormArray;
  }

  get importationTitles(): FormArray {
    return this.paymentForm.get('importationTitles') as FormArray;
  }

  negociationTrackBy(index: number, obj: any): any {
    return index;
  }

  createNewColleagueForm(
    amountPayment,
    id,
    negociationType,
    indicativeExchangeRate,
    negotiatedAmount,
    ticketReference,
    colleague,
    valueDate,
    paymentId
  ): FormGroup {
    const collFrm: FormGroup = this.fb.group({
      negociationType: [negociationType, [Validators.required]],
      id: [id],
      indicativeExchangeRate: [indicativeExchangeRate],
      negotiatedAmount: [negotiatedAmount],
      ticketReference: [ticketReference],
      colleague: [colleague],
      valueDate: [valueDate],
      paymentId: [paymentId, Validators.required]
    });
    if (negociationType != null) {
      this.setNegociationsValidators(amountPayment, collFrm, negociationType);
    }
    this.negotiatedAmountSubscription = (collFrm.get(
      'negotiatedAmount'
    ) as FormControl).valueChanges.subscribe(newVal => {
      this.refinancingForm.get('amountToBeRefinanced').updateValueAndValidity();
    });
    this.negociationTypeSubscription = (collFrm.get(
      'negociationType'
    ) as FormControl).valueChanges.subscribe(newVal => {
      this.setNegociationsValidators(amountPayment, collFrm, newVal);
    });
    return collFrm;
  }
  setNegociationsValidators(
    amountPayment: number,
    collFrm: FormGroup,
    newVal: string
  ) {
    switch (newVal) {
      case NegociationTypes.FIXING:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm.get('negotiatedAmount').clearValidators();
        collFrm.get('ticketReference').clearValidators();
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('indicativeExchangeRate').setValue(null);
        collFrm.get('negotiatedAmount').setValue(null);
        collFrm.get('ticketReference').setValue(null);
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_SGMA:
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([Validators.required, Validators.min(0)]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            PaymentValidators.amountNegociationsValidator(amountPayment),
            Validators.min(0)
          ]);
        collFrm.get('ticketReference').setValidators([Validators.required]);
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_COLLEAGUE:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([Validators.required, Validators.min(0)]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            PaymentValidators.amountNegociationsValidator(amountPayment),
            Validators.min(0)
          ]);
        collFrm.get('ticketReference').setValidators([Validators.required]);
        collFrm.get('colleague').setValidators([Validators.required]);
        collFrm
          .get('valueDate')
          .setValidators([Validators.required, PaymentValidators.minDate]);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
    }
  }

  removeNegociation(i: number): void {
    this.negociations.removeAt(i);
  }

  removeImportationTitle(i: number): void {
    this.importationTitles.removeAt(i);
  }

  isWithColleague(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_COLLEAGUE
    );
  }

  isWithSGMA(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_SGMA
    );
  }

  setRegulationStatus(): string {
    return Util.setStatus('recap', this.payment.status).text;
  }

  addNegociationControl() {
    const paymentId = this.payment ? this.payment.id : null;
    this.negociations.push(
      this.createNewColleagueForm(
        this.payment.amountPayment,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        paymentId
      )
    );
  }

  setPaymentTypeText(): string {
    return Util.setPaymentTypeText(this.payment.paymentType);
  }

  setMinDate(): Date {
    return new Date();
  }

  cancel() {
    return this.router.navigate(['/tasks']);
  }

  savePayment() {
    this.mapFormToPayment();
    this.remdocService.savePayment(this.payment, this.client.id).subscribe(
      res => {
        this.paymentCallBack(this.saveRdiPaymentMessage, false);
        this.getRdiPayment(this.payment.id);
      },
      err => {
        
      }
    );
  }

  submitPayment() {
    this.mapFormToPayment();
    this.remdocService.submitNegociations(this.payment, this.client.id).subscribe(
      res => this.paymentCallBack(this.submitRdiPaymentMessage, true),
      err => {
        
      }
    );
  }

  paymentCallBack(message: string, isSubmitting: boolean) {
    this.remdocPaymentAlert.snackbarData = {
      reference: this.remdoc.requestId,
      message,
      display: { isDisplay: true }
    };
    setTimeout(() => {
      this.remdocPaymentAlert.snackbarData.display.isDisplay = false;
      if (isSubmitting) {
        this.cancel();
      }
    }, 2000);
  }

  mapFormToPayment() {
    this.payment.negociations = this.paymentForm.value.negociations;
    this.payment[
      'importationTitles'
    ] = this.paymentForm.value.importationTitles;
    this.payment['accountNumber'] = this.paymentForm.value.accountNumber;
    this.payment['instructions'] = this.paymentForm.value.instructions;
    this.payment.refinancing = this.paymentForm.value.refinancing;
    this.payment.amountToRefinanced = this.refinancingForm.value.amountToBeRefinanced;
    this.payment.duration = this.refinancingForm.value.duration;
  }

  get instructions(): FormControl {
    return this.paymentForm.get('instructions') as FormControl;
  }

  mapPaymentToForm(payment: PaymentOperation) {
    this.paymentForm.setValue({
      negociations: [],
      importationTitles: [],
      accountNumber: payment['accountNumber'],
      refinancing: payment.refinancing,
      refinancingForm: {
        amountToBeRefinanced: payment.amountToRefinanced,
        duration: payment.duration
      },
      instructions: payment['instructions'] ? payment['instructions'] : ''
    });

    if (payment.refinancing) {
      this.paymentForm
        .get('refinancingForm')
        .get('amountToBeRefinanced')
        .setValidators([
          Validators.required,
          PaymentValidators.amountRefinancingValidator(payment.amountPayment),
          Validators.min(0)
        ]);
    }

    this.negociations.setValue([]);
    if (!payment.negociations || payment.negociations.length === 0) {
      this.addNegociationControl();
    } else {
      payment.negociations.map((n: Negociation) => {
        this.negociations.push(
          this.createNewColleagueForm(
            payment.amountPayment,
            n.id,
            n.negociationType,
            n.indicativeExchangeRate,
            n.negotiatedAmount,
            n.ticketReference,
            n.colleague,
            new Date(n.valueDate),
            payment.id
          )
        );
      });
    }

      this.importationTitles.setValue([]);
      if (
        !payment['importationTitles'] ||
        payment['importationTitles'].length === 0
      ) {
        this.addTitle('');
      } else {
        payment['importationTitles'].forEach((impTitle: string) =>
          this.addTitle(impTitle)
        );
      }
    if (this.remdoc && this.remdoc.type === this.AT_SIGHT) {
      this.importationTitles.enable();
    } else {
      this.importationTitles.disable();
    }
  }
}
