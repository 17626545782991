import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, iif, of } from 'rxjs';
import { Remdoc } from '../models/remdoc';
import { PaymentOperation } from '../models/paymentOperation';
import { mergeMap } from 'rxjs/operators';
import { CreateLcService } from './create-lc.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RemdocService {
  constructor(
    private http: HttpClient,
    private createlcService: CreateLcService
  ) {}

  getListRemdoc(clientId: number): Observable<Remdoc[]> {
    return this.http.get<Remdoc[]>(
      `${environment.url}/remdocImports/clients/${clientId}`,
      httpOptions
    );
  }

  getRemdoc(id: number, clientId: number): Observable<Remdoc> {
    return this.http.get<Remdoc>(
      `${environment.url}/remdocImports/clients/${clientId}/${id}`,
      httpOptions
    );
  }

  printPaymentReport(payementId: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/remdocImports/payment/${payementId}/clients/${clientId}/reporting`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  acceptRemdoc(remdoc: Remdoc, clientId: number): Observable<{}> {
    return this.http.post<{}>(
      `${environment.url}/remdocImports/clients/${clientId}/opening/initiator/accept`,
      remdoc,
      httpOptions
      );
  }



  rejectRemdoc(remdoc: Remdoc, clientId: number): Observable<{}> {
    return this.http.post<{}>(
      `${environment.url}/remdocImports/clients/${clientId}/opening/initiator/reject`,
      remdoc,
      httpOptions
    );
  }

  validateRemdocDecision(id: number, dpResponse: string, challengeKey: string) {
    return this.http.post<void>(
      '/sogetrade-customer/remdocImports/opening/validator/accept',
      { id, dpResponse, challengeKey },
      httpOptions
    );
  }

  rejectRemdocDecision(id: number) {
    return this.http.post<void>(
      '/sogetrade-customer/remdocImports/opening/validator/reject',
      { id },
      httpOptions
    );
  }

  savePayment(payment: PaymentOperation, clientId: number): Observable<void> {
    return this.http
      .post<void>(`${environment.url}/remdocImports/clients/${clientId}/payment`, payment, httpOptions)
      .pipe(
        mergeMap(() =>
          iif(
            () => payment.documentList.length > 0,
            this.createlcService.uploadPaymentDocs(
              payment.id,
              payment.documentList,
              clientId
            ),
            of(null)
          )
        )
      );
  }

  submitNegociations(payment: PaymentOperation, clientId: number): Observable<void> {
    return this.http
      .post<void>(
        `${environment.url}/remdocImports/clients/${clientId}/payment/submit`,
        payment,
        httpOptions
      )
      .pipe(
        mergeMap(() =>
          iif(
            () => payment.documentList.length > 0,
            this.createlcService.uploadPaymentDocs(
              payment.id,
              payment.documentList,
              clientId
            ),
            of(null)
          )
        )
      );
  }
}
