import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';
import { Subscription, Observable } from 'rxjs';
import { Document } from 'src/app/models/document';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import * as FileSaver from 'file-saver';
import { HttpResponse } from '@angular/common/http';
import { ModelType } from 'src/app/models/modelType';
import { Util } from 'src/app/util';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

enum RenderingMode {
  RECAP = 'RECAP',
  FORM = 'FORM'
}

@Component({
  selector: 'app-attached-docs',
  templateUrl: './attached-docs.component.html',
  styleUrls: ['./attached-docs.component.css']
})
export class AttachedDocsComponent implements OnInit, OnDestroy {
  status = false;
  RECAP = 'RECAP';
  /**
   * Input list of files to show
   */
  @Input() documents: Array<Document>;

  /**
   * Input rendering mode
   */

  // tslint:disable-next-line:no-input-rename
  @Input('mode')
  public rendering_mode: RenderingMode;

  @Input()
  public modelType: ModelType;

  /**
   * Confirm dialog
   */
  @ViewChild('confirmDialog')
  private confirmDialog: DialogConfirmComponent;

  private urlDocument: string;

  private getUrlSubscription: Subscription;

  public client: ClientView;

  @Output()
  private remove: EventEmitter<Document> = new EventEmitter<Document>();

  constructor(
    private documentService: DocumentService,
    private clientSharedService: ClientSharedService
    ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
  }

  ngOnDestroy() {
    if (this.getUrlSubscription) {
      this.getUrlSubscription.unsubscribe();
    }
  }

  public removeFile(document: Document) {
    // fire remove event
    this.remove.emit(document);
  }
  public getUrlDocument(doc: Document) {
    let getUrl$: Observable<HttpResponse<Blob>>;

    // Set the observable value
    switch (this.modelType) {
      case ModelType.CREDOC_IMPORT:
        getUrl$ = this.documentService.getUrlCredocImportDocument(doc.id, this.client.id);
        break;

      case ModelType.PAYMENT:
        getUrl$ = this.documentService.getUrlPaymentDocument(doc.id, this.client.id);
        break;

      case ModelType.EXPIRATION:
        getUrl$ = this.documentService.getUrlExpirationDocument(doc.id, this.client.id);
        break;
      case ModelType.RECEIVED_TRANSFER:
        getUrl$ = this.documentService.getUrlReceivedTransferDocument(doc.id, this.client.id);
        break;
      case ModelType.ISSUED_TRANSFER:
        getUrl$ = this.documentService.getUrlIssuedTransferDocument(doc.id, this.client.id);
        break;
      default:
        return;
    }

    // Subscribe to the observable
    this.getUrlSubscription = getUrl$.subscribe(data => {
      const fileName: string = data.headers.get('fileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }
  docsTrackBy(index: number, obj: any): any {
    return index;
  }
}
