import { UserService } from "src/app/services/user.service";
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RemdocService } from 'src/app/services/remdoc.service';
import { Subscription } from 'rxjs';
import {
  Util,
  ProrogationViews,
  Products,
  ExpirationViews
} from 'src/app/util';
import { Remdoc } from 'src/app/models/remdoc';
import { ProrogService } from 'src/app/services/prorog.service';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationEnd } from '@angular/router';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { ExpirService } from 'src/app/services/expir.service';
import * as FileSaver from 'file-saver';
import { ClientView } from "src/app/models/client-view";
import { ClientSharedService } from "src/app/services/client-shared-service";

@Component({
  selector: 'app-rdi-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class RemdocListComponent implements OnInit, OnDestroy {
  private remdocListSubscription: Subscription;
  private navigationSubscription: Subscription;
  public remdocList: Array<Remdoc>;
  private connectedUser: String;
  public client: ClientView;
  constructor(
    private remdocService: RemdocService,
    private prorogService: ProrogService,
    private expirService: ExpirService,
    private dataService: DataService,
    private userService: UserService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.getListRemdoc();
    this.userService.getUserName().subscribe(u => {
      this.connectedUser = u;
     });
  }

  getListRemdoc(): void {
    this.remdocListSubscription = this.remdocService
      .getListRemdoc(this.client.id)
      .subscribe(resp => {
        this.remdocList = resp.sort((rdi1, rdi2) => {
          return rdi2.requestId.localeCompare(rdi1.requestId);
        });
      });
  }

  printPaymentReport(payment: PaymentOperation) {
    this.remdocService.printPaymentReport(payment.id, this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }

  setStatusText(key: string) {
    return Util.setStatus('credoc_list', key).text;
  }
  setStatusColor(key: string) {
    return Util.setStatus('credoc_list', key).color;
  }

  displayProroger(item: Remdoc) {
    return item['payment']['refinancingExtensible'] && item.initiator === this.connectedUser;
  }

  proroger(payment: PaymentOperation) {
    this.prorogService.changeProrogFormData({
      prorogationView: ProrogationViews.PROROGATION_INITIATION
    });

    this.changeDataAndRoute(
      payment.remdocImportId,
      null,
      null,
      null,
      'prorogation/form/' + payment.id
    );
  }

  openRemdocRecap(remdoc: Remdoc) {
    return this.router.navigate(['remdoc/recap/' + remdoc.id]);
  }

  openProrogationRecap(prorogation, remdocImportId) {
    this.prorogService.changeProrogFormData({
      prorogationView: ProrogationViews.PROROGATION_RECAP
    });

    this.changeDataAndRoute(
      remdocImportId,
      null,
      null,
      null,
      'prorogation/recap/' + prorogation.id
    );
  }

  changeDataAndRoute(lcId, operation, summary, paymentId, route) {
    this.changeData(lcId, operation, summary, paymentId);
    this.router.navigate([route]);
  }

  changeData(lcId, operation, summary, paymentId) {
    return this.dataService.changeData({
      product: Products.REMDOC_IMPORT,
      summary,
      lcId,
      paymentId,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: operation,
      taskId: null,
      decision: null
    });
  }

  openPaymentRecap(remdoc: Remdoc) {
    if (remdoc.id && remdoc.payment.id) {
      return this.router.navigate([
        `remdoc/payment/recap/${remdoc.id}/${remdoc.payment.id}`
      ]);
    }
  }

  openExpirRecap(demandeId, paymentId, expirationId) {
    this.expirService.changeExpirFormData({
      expirationView: ExpirationViews.EXPIRATION_RECAP
    });
    this.dataService.changeData({
      product: Products.REMDOC_IMPORT,
      summary: null,
      lcId: demandeId,
      paymentId: paymentId,
      taskId: null,
      taskDefinitionKey: null,
      lastComment: null,
      action: null,
      decision: null
    });
    return this.router.navigate(['expiration/recap/' + expirationId]);
  }

  ngOnDestroy(): void {
    if (this.remdocListSubscription) {
      this.remdocListSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
