import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModeleItem } from '../models/modeleItem';
import { Modele } from '../models/modele';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LettreCredit } from '../models/lettre-credit';

@Injectable({
  providedIn: 'root'
})
export class ModeleService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}
  getModele(id: number, clientId: number): Observable<Modele> {
    return this.http.get<Modele>(`sogetrade-customer/models/credocImport/by/${id}/client/${clientId}`);
  }

  getModeles(clientId: number): Observable<Array<ModeleItem>> {
    return this.http.get<Array<ModeleItem>>(
      `sogetrade-customer/models/credocImport/client/${clientId}`
    );
  }

  deleteModele(id: string, clientId: number): Observable<void> {
    return this.http.delete<void>(`sogetrade-customer/models/credocImport/${id}/client/${clientId}`);
  }

  saveModele(modele: Modele, clientId: number): Observable<number> {
    return this.http.post<number>(
      `sogetrade-customer/models/credocImport/client/${clientId}`,
      modele,
      this.httpOptions
    );
  }
}
