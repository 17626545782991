export const ERROR_MAPPING = {
  TECHNICAL_EXCEPTION: {
    message: 'ERREUR DU SERVEUR:  Une erreur inconnue est survenue.'
  },
  BENEFICIARY_ALREADY_IN_UPDATING: {
    message: 'Bénéficaire est déjà en cours de modification !'
  },
  BENEFICIARY_NOT_EXIST_CLIENT: {
    message: `Le client n'autorise pas le modification de ce bénéficaire !`
  },
  MISSING_FIELD_EXCEPTION: {
    message: 'Champs manquant !'
  },
  FILE_ALREADY_PRESENT: {
    message: 'Fichier déja present dans la liste.'
  },
  'org.springframework.web.multipart.MultipartException': {
    message: 'Taille maximale des fichiers dépassée !'
  },
  CREDOC_IMPORT_ALREADY_HAS_CHILD: {
    message: 'Impossible de lancer cette opération plusieurs fois.'
  },
  PAYMENT_HAS_EXTENSION_IN_PROGRESS: {
    message: 'Une prorogation est déjà en cours ...'
  },
  PAYMENT_NOT_EXIST: {
    message: 'Payment non trouvé.'
  },
  NO_AUTHORZATION: {
    message: 'Pas de schéma/validateur disponible pour ce montant!'
  },
  MODEL_CREDOC_IMPORT_ALREADY_EXIST: {
    message: 'Un modèle avec le même nom existe déjà.'
  },
  INVALID_CHALLENGE_RESPONSE: {
    message: 'Le compte utilisateur Digipass est invalide !'
  },
  USER_LOCKED_CHALLENGE_RESPONSE: {
    message: 'Le compte utilisateur Digipass est verrouillé !'
  },
  USER_EXPIRE_CHALLENGE_RESPONSE: {
    message: 'Le compte utilisateur Digipass est expiré !'
  },
  USER_NOT_FOUND: {
    message: `L'utilisateur n'existe pas!`
  },
  FORBIDDEN: {
    message: `Vous n'avez plus le droit d'accès à cette fonctionnalité !`
  }

};
