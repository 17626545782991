import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationEnd } from '@angular/router';
import {
  Status,
  Operations,
  Util,
  ProrogationViews,
  Products,
  ExpirationViews
} from 'src/app/util';
import { ProrogService } from 'src/app/services/prorog.service';
import { Subscription } from 'rxjs';
import { ExpirService } from 'src/app/services/expir.service';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { UserService } from 'src/app/services/user.service';
import * as FileSaver from 'file-saver';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { Client } from 'src/app/models/client';
import { ClientView } from 'src/app/models/client-view';
import { TasksService } from 'src/app/services/tasks.service';
import { HomeService } from 'src/app/services/home.service';
import { TasksComponent } from 'src/app/core/tasks/tasks.component';

@Component({
  selector: 'app-cdi-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent extends TasksComponent implements OnInit, OnDestroy {
  connectedUser: String;
  public lcs;
  private getLCsSubscription: Subscription;
  private navigationSubscription: Subscription;
  public client: ClientView;
  constructor(
    private createLcService: CreateLcService,
    private userService: UserService,
    private tasksService: TasksService,
    dataService: DataService,
    expirService: ExpirService,
    router: Router,
    prorogService: ProrogService,
    homeService: HomeService,
    clientSharedService: ClientSharedService
  ) {
    super(
      homeService,
      dataService,
      router,
      prorogService,
      expirService,
      clientSharedService
    );
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  deleteButtonClicked = false;
  credocImportIdToDelete;

  ngOnInit() {
    this.client = this.clientSharedservice.getClient();
    this.getLCs(this.client);
    this.userService.getUserName().subscribe(u => {
      this.connectedUser = u;
     });
  }

  ngOnDestroy(): void {
    if (this.getLCsSubscription) {
      this.getLCsSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  setStatusText(key: string) {
    return Util.setStatus('credoc_list', key).text;
  }
  setStatusColor(key: string) {
    return Util.setStatus('credoc_list', key).color;
  }
  displayModifyButton(lc) {
    return (
      [Operations.OPENING, Operations.MODIFICATION].indexOf(lc.operation) >=
        0 &&
      lc.status === Status.EXECUTED &&
      lc.credocImportChildId === null && lc.initiator === this.connectedUser

    );
  }

  displayProroger(lc) {
    return lc.refinancingExtensible && lc.initiator === this.connectedUser;
  }

  setStatusTaskText(key: string) {
    return Util.setStatus('tasks', key).text;
  }

  displayTask(lcRequestId) {
    this.tasksService.getTaskByRequestId(lcRequestId, this.client.id).subscribe(task => {
      this.customRoute(task);
    });
  }

  displayBtnActionInTable(status: string, initiator: string) {
    const username = localStorage.getItem('username') ? localStorage.getItem('username').toLowerCase() : "";

    return ['TO_COMPLETE', 'TO_SIGN', 'TO_REGULARIZE', 'TO_REGULARIZE_MO', 'TO_VALIDATE', 'TO_TREAT'].includes(status)
              && username === (initiator ? initiator.toLowerCase() : "");
  }

  getOperationLablel(operation: string) {
    return Util.setOperationText(operation);
  }

  getLCs(client: ClientView): void {
    this.getLCsSubscription = this.createLcService.getLCs(this.client.id).subscribe(lcs => {
      this.transformAndSortLcs(lcs);
    });
  }

  transformAndSortLcs(lcs): void {
    const sortable = [];
    Object.entries(lcs).forEach(([key, value]) => {
      sortable.push({
        requestId: key,
        credocs: value
      });
    });
    this.lcs = sortable.sort((lcs1, lcs2) => {
      return lcs2.requestId.localeCompare(lcs1.requestId);
    });
  }

  openRecap(lc) {
    this.changeDataAndRoute(lc.id, 'view_recap', true, '', '/lc');
  }
  openPaymentRecap(payment) {
    this.changeDataAndRoute(
      payment.credocImportId,
      'view_recap_payment',
      true,
      payment.id,
      '/lc'
    );
  }

  customRouteLc(lc, operation) {
    const summary = operation === Operations.CANCELLATION ? true : false;
    this.changeDataAndRoute(lc.id, operation, summary, '', '/lc');
  }

  openProrogationRecap(prorogation) {
    this.proroService.changeProrogFormData({
      prorogationView: ProrogationViews.PROROGATION_RECAP
    });

    this.changeDataAndRoute(
      prorogation.credocImportId,
      null,
      null,
      null,
      'prorogation/recap/' + prorogation.id
    );
  }

  openExpirRecap(expiration) {
    this.expirService.changeExpirFormData({
      expirationView: ExpirationViews.EXPIRATION_RECAP
    });
    this.dataService.changeData({
      product: Products.CREDOC_IMPORT,
      summary: null,
      lcId: expiration.credocImportId,
      paymentId: expiration.paymentId,
      taskDefinitionKey: null,
      lastComment: null,
      action: null,
      decision: null,
      taskId: null
    });
    return this.router.navigate(['expiration/recap/' + expiration.id]);
  }

  proroger(payment) {
    this.proroService.changeProrogFormData({
      prorogationView: ProrogationViews.PROROGATION_INITIATION
    });

    this.changeDataAndRoute(
      payment.credocImportId,
      null,
      null,
      null,
      'prorogation/form/' + payment.id
    );
  }

  printPaymentReport(payment) {
    this.createLcService.generatePaymentReport(payment.id, this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }

  printReport(lc: LettreCredit) {
    this.createLcService.printReport(lc.id, this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }

  changeDataAndRoute(lcId, operation, summary, paymentId, route) {
    this.changeData(lcId, operation, summary, paymentId);
    this.router.navigate([route]);
  }

  changeData(lcId, operation, summary, paymentId) {
    return this.dataService.changeData({
      product: Products.CREDOC_IMPORT,
      summary,
      lcId,
      paymentId,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: operation,
      decision: null,
      taskId: null
    });
  }
  newCredoc() {
    this.router.navigate(['/lc']);
    return;
  }

  displayDeletingButton(lc) {
    return (
      [Operations.OPENING, Operations.MODIFICATION].indexOf(lc.operation) >=
        0 &&
      lc.status === Status.TO_COMPLETE && lc.initiator === this.connectedUser
    );
  }

  deleteCredocImport(credocId) {
    this.createLcService.deleteCredocImport(credocId, this.client.id).subscribe(res =>
       { this.deleteButtonClicked = false;
        this.router.navigate(['/lc/list'])
      }
      , err => {});
  }

}
