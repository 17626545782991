import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Util,
  ProrogationViews,
  Products,
  ExpirationViews
} from 'src/app/util';
import { IssuedTransferService } from 'src/app/services/issued-transfer.service';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { Subscription } from 'rxjs';
import { ProrogService } from 'src/app/services/prorog.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { ExpirService } from 'src/app/services/expir.service';
import * as FileSaver from 'file-saver';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { TasksService } from 'src/app/services/tasks.service';
import { HomeService } from 'src/app/services/home.service';
import { TasksComponent } from 'src/app/core/tasks/tasks.component';
import { SwiftDocument } from 'src/app/models/swiftDocument';

@Component({
  selector: 'app-issued-list',
  templateUrl: './issued-list.component.html',
  styleUrls: ['./issued-list.component.css']
})
export class IssuedListComponent extends TasksComponent implements OnInit, OnDestroy {
  hideDeleteModel = true;
  deleteModeleMessage =
  'Etês-vous sûr vouloir supprimer le virement séléctionné ?';
  selectedTransferIssued: IssuedTransfer = null;
  issuedList: Array<IssuedTransfer>;
  private issuedListSubscription: Subscription;
  private navigationSubscription: Subscription;
  private userSubscription: Subscription;
  public client: ClientView;
  connectedUser: String;
  page = { currentPage: 0, totalPages: null, totalElements: null };
  search = '';
  constructor(
    private issuedService: IssuedTransferService,
    private issuedTransferService: IssuedTransferService,
    private userService: UserService,
    private tasksService: TasksService,
    dataService: DataService,
    expirService: ExpirService,
    router: Router,
    prorogService: ProrogService,
    homeService: HomeService,
    clientSharedService: ClientSharedService
  ) {
    super(
      homeService,
      dataService,
      router,
      prorogService,
      expirService,
      clientSharedService
    );

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }

  ngOnInit() {
    this.client = this.clientSharedservice.getClient();
    this.getIssuedList();
    this.userService.getUserName().subscribe(u => {
     this.connectedUser = u;
    });
  }

  getIssuedList(): void {
    this.issuedListSubscription = this.issuedService
      .getIssuedTransferList(this.page.currentPage, this.search, this.client.id)
      .subscribe(resp => {
        this.issuedList = resp['contentList'].sort((vre1, vre2) => {
          return vre2.requestId.localeCompare(vre1.requestId);
        });
        this.issuedList.forEach((issued, _index) => {
          issued.refinancingExtensions.sort((ext1, ext2) => {
            const date1 = ext1.addDate;
            const date2 = ext2.addDate;
            if (date1 > date2) {
              return -1;
            } else if (date1 < date2) {
              return 1;
            } else {
              return 0;
            }
          });
        });
        this.page.totalPages = resp['totalPages'];
        this.page.totalElements = resp['totalElements'];
      });
  }

  setStatusText(key: string) {
    return Util.setStatus('credoc_list', key).text;
  }

  setStatusColor(key: string) {
    return Util.setStatus('credoc_list', key).color;
  }

  setStatusTaskText(key: string) {
    return Util.setStatus('tasks', key).text;
  }

  displayTask(ist: IssuedTransfer) {
    this.tasksService.getTaskByRequestId(ist.requestId, this.client.id).subscribe(task => {
      this.customRoute(task);
    });
  }

  displayBtnActionInTable(status: string, initiator: string) {
    const username = localStorage.getItem('username') ? localStorage.getItem('username').toLowerCase() : "";

    return ['TO_COMPLETE', 'TO_SIGN', 'TO_REGULARIZE', 'TO_REGULARIZE_MO', 'TO_VALIDATE', 'TO_TREAT'].includes(status)
              && username === (initiator ? initiator.toLowerCase() : "");
  }

  ngOnDestroy(): void {
    if (this.issuedListSubscription) {
      this.issuedListSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  proroger(issued: IssuedTransfer) {
    this.proroService.changeProrogFormData({
      prorogationView: ProrogationViews.PROROGATION_INITIATION
    });

    this.changeDataAndRoute(
      issued.id,
      null,
      null,
      null,
      'prorogation/form/' + issued.id
    );
  }

  showDelete(issued: IssuedTransfer) {
    return 'TO_COMPLETE' === issued.status && issued.initiator === this.connectedUser;

  }

  displayProroger(issuedItem) {
    return issuedItem.refinancingExtensible && issuedItem.initiator === this.connectedUser;
  }

  delete() {
    this.issuedService.delete(this.selectedTransferIssued.id, this.client.id).subscribe(() => {
      this.issuedList = this.issuedList.filter(transfer =>  transfer.id !== this.selectedTransferIssued.id);
      this.hideDeleteModel = true;
    });
   }

   showModal(issued: IssuedTransfer) {
      this.selectedTransferIssued = issued;
      this.hideDeleteModel = false;
   }

   hideModal(issued: IssuedTransfer) {
    this.selectedTransferIssued = null;
    this.hideDeleteModel = true;
 }

  changeDataAndRoute(lcId, operation, summary, paymentId, route) {
    this.changeData(lcId, operation, summary, paymentId);
    this.router.navigate([route]);
  }

  changeData(lcId, operation, summary, paymentId) {
    return this.dataService.changeData({
      product: Products.TRANSFER_ISSUED,
      summary,
      lcId,
      paymentId,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: operation,
      decision: null,
      taskId: null
    });
  }

  openProrogationRecap(issued: IssuedTransfer, extId: number): void {
    this.issuedService.changeData({
      extId,
      issued
    });
    this.router.navigate(['issued-transfer/list-recap/ext']);
    return;
  }

  openExpirRecap(demandeId, expirationId) {
    this.expirService.changeExpirFormData({
      expirationView: ExpirationViews.EXPIRATION_RECAP
    });
    this.dataService.changeData({
      product: Products.TRANSFER_ISSUED,
      summary: null,
      lcId: demandeId,
      paymentId: null,
      taskDefinitionKey: null,
      lastComment: null,
      action: null,
      decision: null,
      taskId: null
    });
    return this.router.navigate(['expiration/recap/' + expirationId]);
  }

  printReport(issuedTransferId) {
    this.issuedTransferService.printReport(issuedTransferId, this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }
  
  displayBtnSwift(status: string) {

    return 'BANK_EXECUTE' === status;
  }

  downloadSwift(issuedTransferId) {
    this.issuedTransferService.downloadSwift(issuedTransferId).subscribe(data => {
      var dec = new TextDecoder("utf-8");
      var str = dec.decode(data);

      const blob = this.b64toBlob(str, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      FileSaver.saveAs(blob, 'SWIFT_'+issuedTransferId+'.pdf');
    });
  }

  b64toBlob(b64Data, contentType='', sliceSize=512) : Blob {
    let byteCharacters = b64Data;
    try {
      byteCharacters = atob(b64Data);
    } catch(error) {}
    return this.createBlob(byteCharacters, contentType, sliceSize);
  }

  createBlob(byteCharacters, contentType='', sliceSize=512) : Blob {
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  openIssuedRecap(issued: IssuedTransfer) {
    this.issuedService.changeData({
      extId: null,
      issued
    });
    this.router.navigate(['issued-transfer/list-recap/issued']);
    return;
  }
}
